const initialState = {
  filterMenuData: [],
  filterMasterValuesData: [],
  formMenuData: [],
  formMasterValuesData: [],
  tableConfig: {},
  isUserSessionActive: false,
  hideTableKeys: true,
  tableFilters: {},
  filterPanelsData: {},
  filtersData: {},
  filterOptions: {},
  selectedFilterValues:{}
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FILTER_DATA":
      const { tb_filter_master_copy, tb_filter_master_values } = action.payload;
      return {
        ...state,
        filterMenuData: tb_filter_master_copy,
        filterMasterValuesData: tb_filter_master_values,
      };

    case "UPDATE_FORM_DATA":
      const { tb_form_master, tb_form_master_values } = action.payload;
      return {
        ...state,
        formMenuData: tb_form_master,
        formMasterValuesData: tb_form_master_values,
        tableConfig: action.payload.table_config,
        filterMenuData: action.payload.tb_filter_master_copy,
        filterMasterValuesData: action.payload.tb_filter_master_values,

        // tableConfig: table_config
      };
    case "ADD_FORM_MASTER_VALUES":
      return {
        ...state,
        formMasterValuesData: [
          ...state.formMasterValuesData,
          ...action.payload,
        ],
      };
    case "ADD_NEW_FORM_MASTER_VALUES":
      return {
        ...state,
        formMasterValuesData: [...action.payload],
      };
    case "UPDATE_TABLE_CONFIG":
      const { table_config } = action.payload;
      return {
        ...state,
        tableConfig: table_config,
      };
    case "USER_SESSION_UPDATED":
      const { userSession } = action.payload;
      return {
        ...state,
        isUserSessionActive: userSession,
      };
    case "DISPLAY_TABLE_KEYS":
      return {
        ...state,
        hideTableKeys: action.payload,
      };
    case "UPDATE_TABLE_FILTERS":
      return {
        ...state,
        tableFilters: { ...state.tableFilters, ...action.payload },
      };
    case "UPDATE_FILTER_PANEL_DATA":
      return {
        ...state,
        filterPanelsData:{...state?.filterPanelsData, ...action.payload}
      };
    case "SET_FILTERS_DATA":
      return {
        ...state,
        filtersData: { ...action?.payload?.filtersData },
        filterOptions: { ...action?.payload?.filterOptions },
      };
    case "SET_SELECTED_FILTER_VALUES":
      return {
        ...state,
        selectedFilterValues: {
          ...action.payload
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
