import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsTreeChart from "highcharts/modules/treemap";
import Drilldown from "highcharts/modules/drilldown";
import { lineChartOptions } from "./formatter/LineChart";
import { lineChartPerformanceOptions } from "./formatter/LineChartPerformance";
import { stepChartOptions } from "./formatter/StepChart";
import { HeatmapOptions } from "./formatter/Heatmap";
import HighchartsHeatmap from "highcharts/modules/heatmap";

import { BarChartOptions } from "./formatter/BarChart";
import { treemapOptions } from "./formatter/Treemap";
import _ from "lodash";
import "./Chart.scss";
import { lineChartAreaOptions } from "./formatter/LineChartArea";
import { stepAreaChartOptions } from "./formatter/StepArea";
import { WaterfallChartOption } from "./formatter/WaterfallChart";
import HighchartsMore from "highcharts/highcharts-more";
import {
  ColumnChartOptions,
  scatterChartOptions,
  bubbleChartOptions,
} from "./formatter/ColumnChart";
import { circularChartOptions } from "./formatter/CircularChart";
import { treemapwithlabelOptions } from "./formatter/TreeMapWithLabel";
import { donutChartOptions } from "./formatter/DonutChart";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";

const Chart = (props) => {
  const navigate = useNavigate();
  //console.log('props received in charts -->', props)
  let options = [];
  switch (props.chartType) {
    case "linechart-performance":
      options = lineChartPerformanceOptions(_.cloneDeep(props));
      break;
    case "linechart-area":
      options = lineChartAreaOptions(_.cloneDeep(props));
      break;
    case "linechart":
      options = lineChartOptions(_.cloneDeep(props));
      break;
    case "barchart":
      options = BarChartOptions(_.cloneDeep(props));
      break;
    case "step":
      options = stepChartOptions(_.cloneDeep(props));
      break;
    case "step-area":
      options = stepAreaChartOptions(_.cloneDeep(props));
      break;
    case "heatmap":
      options = HeatmapOptions(_.cloneDeep(props));
      break;
    case "treemap":
      options = treemapOptions(_.cloneDeep(props));
      break;
    case "column-percent":
      options = ColumnChartOptions(_.cloneDeep(props));
      break;
    case "horizontal-stacked":
      options = ColumnChartOptions(_.cloneDeep(props));
      break;
    case "scatter":
      options = scatterChartOptions(_.cloneDeep(props));
      break;
    case "waterfall":
      options = WaterfallChartOption(_.cloneDeep(props));
      break;
    case "circularChart":
      options = circularChartOptions(_.cloneDeep(props));
      break;
    case "treemapwithlabel":
      options = treemapwithlabelOptions(_.cloneDeep(props));
      break;
    case "donutchart":
      options = donutChartOptions(_.cloneDeep(props));
      break;
    case "bubblechart":
      options = bubbleChartOptions(_.cloneDeep(props));
      break;
    default:
      break;
  }
  if (props.isNavigationRequired) {
    options.plotOptions = {
      series: {
        cursor: "pointer",
        events: {
          click: (event) => {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                props.navigationPath
            );
          },
        },
      },
    };
  }
  if (props.customizedLabels) {
    options.tooltip = {
      formatter: function () {
        let label1 = "";
        let label2 = "";
        switch (this.point.value) {
          case 6:
            label1 = props.customizedLabels[this.point.value][0];
            label2 = props.customizedLabels[this.point.value][1];
            break;
          case 6.8:
            label1 = props.customizedLabels[this.point.value][0];
            label2 = props.customizedLabels[this.point.value][1];
            break;
          case 7.8:
            label1 = props.customizedLabels[this.point.value][0];
            label2 = props.customizedLabels[this.point.value][1];
            break;
          case 9:
            label1 = props.customizedLabels[this.point.value][0];
            label2 = props.customizedLabels[this.point.value][1];
            break;
          default:
            break;
        }
        return ` <b>${label1} ` + `</br>${label2} <b>`;
      },
    };
  } else if (props.uniqueKey === "tradesmart") {
    options.tooltip = {
      formatter: function () {
        return (
          "Promo Type: <b> All Accounts - % Off </b> <br>" +
          "Spend: <b> $1,714k </b> <br>" +
          "Volume: <b> 264k </b> <br>" +
          "Revenue: <b> $3,968k  </b> <br>" +
          "Incr. Margin: <b> $1,356k  </b> <br>" +
          "ROI: <b> 79%  </b> <br>"
        );
      },
    };
  } else if (props.uniqueKey === "mondaysmart") {
    options.tooltip = {
      formatter: function () {
        return this.points.reduce((s, point) => {
          return (
            s + `<br/>${point.series.name}: ${numeral(point.y).format("0.0a")}`
          );
        }, `<b>${this.x == "-" ? "" : this.x}</b>`);
      },
      shared: true,
    };
  } else {
    if (!props.hideToolTip) {
      options.tooltip = {
        //   formatter: function () {
        //     return this.points.reduce(function (s, point) {
        //         return s + '<br/>' + point.series.name + ': ' +
        //             point.y + 'm';
        //     }, '<b>' + this.x + '</b>');
        // },
        // shared: true
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding-left:5px"><b>{point.y:.2f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      };
    }
  }
  if (props.chartType === "treemap" || props.chartType === "treemapwithlabel") {
    HighchartsTreeChart(Highcharts);
  }
  if (props.chartType === "heatmap") {
    HighchartsHeatmap(Highcharts);
  }

  Drilldown(Highcharts);
  Highcharts.setOptions({
    lang: {
      drillUpText: "Back to {series.name}",
    },
  });

  if (props.drilldown || props.select) {
    /* Selected points - stores id of selected items. Store full objects if need be */
    (function (H) {
      H.addEvent(H.Chart, "load", function (e) {
        /* Storing selectedPoints locally to support multiple selection */
        let selectedPoints = [];
        /* Custom Select event */
        if (props.select) {
          var chart = e.target;
          H.addEvent(chart.container, "click", function (e) {
            e = chart.pointer.normalize(e);
            if (e.point && e.point.id) {
              if (selectedPoints.includes(e.point.id)) {
                const index = selectedPoints.indexOf(e.point.id);
                if (index > -1) {
                  selectedPoints.splice(index, 1); // 2nd parameter means remove one item only
                }
              } else {
                selectedPoints.push(e.point.id);
              }

              // Send selected items to parent component
              props.setSelectedItems && props.setSelectedItems(e.point.id);

              var points = chart.series[0].data;
              points
                .filter((elem) => {
                  return selectedPoints.includes(elem.id);
                })
                .forEach((point) => {
                  point.select(true, true);
                });
            }
          });
        }

        /* Custom drilldown event */
        if (props.drilldown) {
          H.addEvent(chart.container, "dblclick", function (e) {
            e = chart.pointer.normalize(e);
            const selectedPoint = e.target.point
              ? {
                  id: e.target.point.id,
                  name: e.target.point.name,
                }
              : {};
            props.handleDrilldown && props.handleDrilldown(selectedPoint);
          });
        }
      });
    })(Highcharts);
  }
  HighchartsMore(Highcharts);

  return (
    <HighchartsReact
      ref={props.chartComponent}
      highcharts={Highcharts}
      options={{
        ...options,
        credits: {
          enabled: false,
        },
      }}
      allowChartUpdate={true}
      immutable={true}
      containerProps={{ style: { height: "100%" } }}
    />
  );
};

export default Chart;
