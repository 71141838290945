import React, { useEffect, useState, useRef, useMemo } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import LoadingOverlay from "../../../../componentsV2/LoadingOverlay";
import Chart from "../../../../componentsV2/Charts/Chart";
import { getMultipleModelsData } from "../../action";

const CustomChart = ({ data }) => {
  const { apiKeys, info } = data;
  const chartComponent = useRef(null);
  const containerRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const { componentKey, apiKey } = info;
  const dispatch = useDispatch();
  const { componentInfo } = useSelector((state) => state.home);
  const [chartInfo, setChartInfo] = useState(info);
  const [xAxisData, setXAxisData] = useState(info.xaxis);
  const [yAxisData, setYAxisData] = useState(info.yaxis);
  const [seriesData, setSeriesData] = useState(info.data);
  useEffect(async () => {
    //save all apiKeys data to store
    if (apiKeys) {
      setLoader(true);
      const res = await getMultipleModelsData({
        model_names: apiKeys,
      });
      const params = {};
      apiKeys.forEach((key, index) => {
        params[key] = res[key] || [];
      });
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
      dispatch({
        type: "COMPONENT_INFO",
        payload: {
          [componentKey]: {
            data: params[apiKey],
          },
        },
      });
      setLoader(false);
    }
  }, [apiKeys, componentKey, apiKey]);

  const graphData = useMemo(() => {
    if (componentInfo[componentKey]) {
      return componentInfo[componentKey].data || [];
    }
    return [];
  }, [componentInfo, componentKey]);

  useEffect(() => {
    setChartInfo(updateChartInfo(info, graphData));
    setXAxisData(getXaxis(info, graphData));
    setYAxisData(getYaxis(info, graphData));
    setSeriesData(setupSeriesData(info, graphData));
  }, [graphData, info]);

  function updateChartInfo(info, graphData) {
    let params = {};
    switch (info.type) {
      case "heatmap":
        params = { stops: [] };
        break;
      default:
        break;
    }
    for (let i = 0; i < graphData.length; i += 1) {
      const row = graphData[i];
      Object.keys(params).forEach((key) => {
        let v = row[`info_${key}`];
        if (v) {
          if (Array.isArray(params[key])) {
            params[key].push(infoResolver(key, v, i, info));
          } else {
            params[key] = infoResolver(key, v);
          }
        }
      });
    }
    return { ...info, ...params };
  }
  function setupSeriesData(obj, graphData) {
    const { data, type } = obj;
    const legendProps = [];
    for (let i = 0; i < graphData.length; i++) {
      const v = graphData[i]["#legendProps"];
      if (!v) break;
      legendProps.push(v);
    }
    return data.map((obj) => {
      const params = { ...obj };
      params.data = graphData.map((row) => {
        const cellValue = row[obj.series];
        if (cellValue) {
          switch (type) {
            case "scatter":
            case "heatmap": {
              return cellValue
                .split(",")
                .map((v) => Number(v.replaceAll(",", "")));
            }
            default:
              return Number(cellValue.replaceAll(",", ""));
          }
        }
      });
      if (legendProps.length) {
        legendProps.forEach(
          (key, index) =>
            (params[key] = graphData[index][`${obj.series}_legend`])
        );
      }
      return params;
    });
  }
  function getYaxis(obj, graphData) {
    const params = {
      categories: [],
      title: "",
    };
    for (let i = 0; i < graphData.length; i += 1) {
      const row = graphData[i];
      Object.keys(params).forEach((key) => {
        let v = row[`yAxis_${key}`];
        if (v) {
          if (Array.isArray(params[key])) {
            params[key].push(xAxisDataResolver(key, v, i, obj));
          } else {
            params[key] = xAxisDataResolver(key, v);
          }
        }
      });
    }
    const yAxis = { ...obj.yaxis };
    Object.keys(params).forEach((key) => {
      const v = params[key];
      if (v) {
        if (!Array.isArray(v)) {
          yAxis[key] = v;
        } else if (v.length) {
          yAxis[key] = v;
        }
      }
    });
    return yAxis;
  }
  function getXaxis(obj, graphData) {
    const params = {
      title: "",
      categories: [],
      plotBands: [],
      plotLines: [],
    };
    for (let i = 0; i < graphData.length; i += 1) {
      const row = graphData[i];
      Object.keys(params).forEach((key) => {
        let v = row[`xAxis_${key}`];
        if (key === "categories") {
          v = v || row["xAxis"];
        }
        if (v) {
          if (Array.isArray(params[key])) {
            params[key].push(xAxisDataResolver(key, v, i, obj));
          } else {
            params[key] = xAxisDataResolver(key, v);
          }
        }
      });
    }
    const xAxis = { ...obj.xaxis };
    Object.keys(params).forEach((key) => {
      const v = params[key];
      if (v) {
        if (!Array.isArray(v)) {
          xAxis[key] = v;
        } else if (v.length) {
          xAxis[key] = v;
        }
      }
    });
    return xAxis;
  }
  function xAxisDataResolver(key, value, index, obj) {
    switch (key) {
      case "categories":
        const { categoryGenerator } = obj;
        if (categoryGenerator) {
          switch (categoryGenerator.type) {
            case "currentDate": {
              const { startIndex, gap } = categoryGenerator.info;
              const offset = (index - startIndex) * gap;
              const v = moment().add(offset, "days").format("D MMM YY");
              return v;
            }
            case "currentMonth": {
              const { startIndex, gap } = categoryGenerator.info;
              const offset = (index - startIndex) * gap;
              const v = moment().add(offset, "months").format("YYYYMM");
              return v;
            }
            default:
              break;
          }
        }
        return value;
      case "plotBands":
        const arr = value.split(",");
        return {
          from: Number(arr[0]),
          to: Number(arr[1]),
          label: {
            text: arr[2],
          },
          color: arr[3],
        };
      case "plotLines":
        const dataSet = value.split(",");
        return {
          label: {
            text: dataSet[0],
            style: {
              fontSize: "10px",
              color: "rgb(197,197,197)",
            },
          },
          color: "rgb(197,197,197)",
          dasStyle: "dash",
          value: Number(dataSet[1]),
          zIndex: 1,
        };

      default:
        return value;
    }
  }
  function infoResolver(key, value, index, info) {
    switch (key) {
      case "stops":
        const arr = value.split(",");
        return [Number(arr[0]), arr[1]];
      default:
        return value;
    }
  }
  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (chartComponent.current) {
            const chartRef = chartComponent.current.chart;
            chartRef.setSize(
              entry.contentRect.width,
              chartRef.chartHeight,
              false
            );
          }
        }
      });
      observer.observe(containerRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <LoadingOverlay
      loader={loader}
      className={"impact-plan-smart-them" + " impact-loading-overlay"}
    >
      <div
        ref={containerRef}
        className="chart-container"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Chart
          {...chartInfo}
          chartType={chartInfo.type}
          chartComponent={chartComponent}
          width="100%"
          yaxis={yAxisData}
          xaxis={xAxisData}
          data={seriesData}
        />
      </div>
    </LoadingOverlay>
  );
};

export default CustomChart;
