import useAction from "../../../utils/useAction";
export const WaterfallChartOption = (props) => {
    const invokeAction = useAction();

    const addActionsInData = (data) => {
        const initialData = data?.[0]?.data;
        const dataWithActions = initialData?.map((data) => {
            return data?.action
                ? {
                      ...data,
                      events: {
                          click: function () {
                              invokeAction(data?.action);
                          },
                      },
                  }
                : { ...data };
        });
        const modifiedData = [{ ...data[0], data: dataWithActions }];
        return modifiedData;
    };

    return {
        chart: {
            type: "waterfall",
            ...props.chartProps,
        },
        title: {
            text: props.title,
            ...props.titleProps,
        },
        xAxis: {
            title: {
                text: props.xaxis.title,
            },
            categories: props.xaxis.categories,
            ...props.xaxis?.otherProps,
        },
        yAxis: {
            title: {
                text: props.yaxis.title,
            },
            ...props.yaxis?.otherProps,
        },
        legend: {
            enabled: props?.showLegend,
        },
        tooltip: {
            enabled: false,
        },
        series: props.actionOnBars ? addActionsInData(props.data) : props.data,
        credits: {
            enabled: false,
        },
        ...props.otherProps,
    };
};
